export default {
  in: {
    ACSPhotonics: {
      title: 'ACS Photonics front cover paper',
      link: '1Dgrating',
      image: 'acs',
      description: 'Designing free-form light deflector using RL',
      tag: 'RL',
    },
    // stressTest: {
    //   title: 'stress test',
    //   link: 'stressTest',
    //   image: 'acs',
    //   description: 'stressTest',
    //   tag: '.',
    // },
    Navigation: {
      title: ' Image Retrieval based Robot Global Localization',
      link: 'Localization',
      image: 'navigation',
      description:
        'Applied a deep learning model to the Global Localization and simulated it',
      tag: 'CV',
    },
    // PoseEstimation: {
    //   title: 'Pose Estimation',
    //   link: 'PoseEstimation',
    //   image: 'poseestimation',
    //   description: 'SFPR for Human Pose Estimation',
    //   tag: 'CV',
    // },
    // OOD: {
    //   title: 'Oriented Object Detection',
    //   link: 'OOD',
    //   image: 'ood',
    //   description:
    //     'Identify object headings by employing object detection models',
    //   tag: 'CV',
    // },
    // ImageRestoration: {
    //   title: 'Image Restoration',
    //   link: 'ImageRestoration',
    //   image: 'ood',
    //   description: 'Image Restoration',
    //   tag: 'CV',
    // },
  },

  out: {
    llvmflow: {
      title: 'LLVM FLOW',
      link: 'https://llvm-flow-git-develop-kc-ml-2.vercel.app/',
      image: 'llvmflow',
      description:
        'An open-source interactive visualization tool for comparing IR CFG before and after optimization.',
      tag: 'OSS',
    },
  },

  soon: {
    PoseEstimation: {
      title: 'Pose Estimation',
      link: 'PoseEstimation',
      image: 'poseestimation',
      description: 'SFPR for Human Pose Estimation',
      tag: 'CV',
    },
    OOD: {
      title: 'Oriented Object Detection',
      link: 'OOD',
      image: 'ood',
      description:
        'Identify object headings by employing object detection models',
      tag: 'CV',
    },
    Blockchain: {
      title: 'Blockchain Graph',
      link: 'Blockchain',
      image: 'blockchain',
      description: 'Exploratory analysis on normal vs abnormal graphs',
      tag: 'Graph',
    },
  },
}
