import { useState, useEffect, useRef } from 'react'
import air from 'images/demo/acs/white.png'
import silicon from 'images/demo/acs/blue.png'
import red from 'images/demo/acs/red.png'
import no from 'images/demo/acs/64number.png'
import point from 'images/demo/acs/point.png'
import click from 'images/demo/acs/click.gif'
import styles from './Structure.module.scss'
import axios from 'axios'
import Select from 'react-select'
import { useAppSelector, useAppDispatch } from '../../../redux/hook'
import {
  setWaveLength,
  setAngle,
  setCell,
} from '../../../redux/features/acspaperSlice'
import initialInput from './initialInput'
import { encoded } from './encoded'
import { MathComponent } from 'mathjax-react'

const Structure = () => {
  const { REACT_APP_ACSPAPER_API_URL } = process.env

  const dispatch = useAppDispatch()

  // 실험 조건
  const waveLengthOption = [
    { value: 900, label: 900 },
    { value: 1000, label: 1000 },
    { value: 1100, label: 1100 },
  ]

  const angleOption = [
    { value: 50, label: 50 },
    { value: 60, label: 60 },
    { value: 70, label: 70 },
  ]

  const handleWaveLength = (e: any) => {
    dispatch(setWaveLength(parseFloat(e.value)))
  }

  const handleAngle = (e: any) => {
    dispatch(setAngle(parseFloat(e.value)))
  }

  const { waveLength, angle, cell } = useAppSelector((state) => state.acspaper)

  const [input, setInput] = useState<Array<number>>(initialInput[90050])
  const structureArray = Array.from({ length: 64 }, (_, i) => i)

  const ref = useRef(null)

  const [efficiency, setEfficiency] = useState<number>(0.9874681)
  const [encodedImg, setEncodedImg] = useState(encoded)
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [impact, setImpact] = useState<boolean>(true)

  useEffect(() => {
    if (impact == false) {
      const data = {
        inputData: input,
        inputWavelength: waveLength,
        inputDeflected_angle: angle,
      }
      axios
        .post(`${REACT_APP_ACSPAPER_API_URL}/acspaper`, data)
        .then((response) => {
          setEfficiency(response.data.output[2])
          setEncodedImg(response.data.graph)
          setLoading(false)
          setDisabled(false)
        })
        .catch((response) => console.log(response))
    }
  }, [input])

  // 실험조건 변경되면 structure도 초기화
  useEffect(() => {
    if (waveLength == 900 && angle == 50) {
      setInput(initialInput[90050])
    } else if (waveLength == 900 && angle == 60) {
      setInput(initialInput[90060])
      setImpact(false)
    } else if (waveLength == 900 && angle == 70) {
      setInput(initialInput[90070])
      setImpact(false)
    } else if (waveLength == 1000 && angle == 50) {
      setInput(initialInput[100050])
      setImpact(false)
    } else if (waveLength == 1000 && angle == 60) {
      setInput(initialInput[100060])
      setImpact(false)
    } else if (waveLength == 1000 && angle == 70) {
      setInput(initialInput[100070])
      setImpact(false)
    } else if (waveLength == 1100 && angle == 50) {
      setInput(initialInput[110050])
      setImpact(false)
    } else if (waveLength == 1100 && angle == 60) {
      setInput(initialInput[110060])
      setImpact(false)
    } else if (waveLength == 1100 && angle == 70) {
      setInput(initialInput[110070])
      setImpact(false)
    }
  }, [waveLength, angle])

  const changeInput = (index: number, value: number) => {
    if (value === -1) {
      input[index] = 1
      setInput([...input])
    } else if (value === 1) {
      input[index] = -1
      setInput([...input])
    }
    4
  }

  const changeStructure = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target instanceof Image) {
      setImpact(false)
      setDisabled(true)
      setLoading(true)
      if (e.target.src === air) {
        changeInput(parseInt(e.target.id), -1)
      } else {
        changeInput(parseInt(e.target.id), 1)
      }
    }
  }

  return (
    <section className={styles.demo}>
      <section id={styles.left}>
        <section id={styles.top}>
          {impact == true ? (
            <p className={styles.terminal}>
              <img src={click} height="71" width="107"></img>
              <b>Click</b> the cell marked by the red arrow.
            </p>
          ) : (
            <p className={styles.terminal}>
              It changed !<br></br>
              Click other cells to see how the electric field and deflection
              efficiency change.
              <br></br>
              <i>You can alose change the conditions(Wavelength, Angle).</i>
            </p>
          )}
        </section>
        <section id={styles.middle}>
          <section className={styles.circle}>
            <div>
              <span id={styles.circle_silicon}></span> &nbsp;{' '}
              <span>Silicon</span>
            </div>
            <br></br>
            <div>
              <span id={styles.circle_air}></span> &nbsp; <span>Air</span>
            </div>
          </section>
          <section className={styles.option}>
            <span id={styles.option_wavelength}>
              Wave Length [nm]
              <Select
                options={waveLengthOption}
                onChange={handleWaveLength}
                defaultValue={{ value: waveLength, label: waveLength }}
              />
            </span>
            <span id={styles.option_angle}>
              Angle [°]
              <Select
                options={angleOption}
                onChange={handleAngle}
                defaultValue={{ value: angle, label: angle }}
              />
            </span>
          </section>
        </section>
        <section id={styles.bottom} ref={ref}>
          {impact == true ? (
            <>
              <img src={point} id={styles.point} /> <br></br>
            </>
          ) : null}
          {structureArray.map((i) => (
            <button
              type="button"
              onClick={changeStructure}
              key={i}
              disabled={disabled || loading}
            >
              <img
                src={input[i] === -1 ? air : silicon}
                width="12"
                height="90"
                id={`${i}`}
                className={styles.cell64}
              />
            </button>
          ))}
          <img src={no} width="768" />
          <br></br>
          <div className={styles.cell}>
            Select number of cells :{' '}
            <button
              onClick={() => {
                dispatch(setCell(64))
              }}
              id={cell == 64 ? styles.active : styles.deactive}
            >
              64
            </button>
            <button
              onClick={() => {
                dispatch(setCell(32))
              }}
              id={cell == 32 ? styles.active : styles.deactive}
            >
              32
            </button>
            <button
              onClick={() => {
                dispatch(setCell(16))
              }}
              id={cell == 16 ? styles.active : styles.deactive}
            >
              16
            </button>
          </div>
        </section>
      </section>
      <section id={styles.right}>
        <p>
          η : <b>{(efficiency * 100).toFixed(5)}%</b>
        </p>
        <MathComponent tex={String.raw`\mathbf{E}_x/|\mathbf{E}_0 |`} />
        <img
          src={`data:image/png;base64,${encodedImg}`}
          alt="Please wait for the figure"
          height="418"
        />
      </section>
    </section>
  )
}

export default Structure
