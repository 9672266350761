import { useState, useEffect, useRef } from 'react'
import air from 'images/demo/acs/white16.png'
import silicon from 'images/demo/acs/blue16.png'
import no from 'images/demo/acs/16number.png'
import styles from './Structure.module.scss'
import axios from 'axios'
import Select from 'react-select'
import { useAppSelector, useAppDispatch } from '../../../redux/hook'
import {
  setWaveLength,
  setAngle,
  setCell,
} from '../../../redux/features/acspaperSlice'
import initialInput from './initialInput'
import { encoded16 } from './encoded16'
import { MathComponent } from 'mathjax-react'

const Structure = () => {
  const { REACT_APP_ACSPAPER_API_URL } = process.env

  const dispatch = useAppDispatch()

  // 실험 조건
  const waveLengthOption = [
    { value: 900, label: 900 },
    { value: 1000, label: 1000 },
    { value: 1100, label: 1100 },
  ]

  const angleOption = [
    { value: 50, label: 50 },
    { value: 60, label: 60 },
    { value: 70, label: 70 },
  ]

  const handleWaveLength = (e: any) => {
    dispatch(setWaveLength(parseFloat(e.value)))
  }

  const handleAngle = (e: any) => {
    dispatch(setAngle(parseFloat(e.value)))
  }

  const { waveLength, angle, cell } = useAppSelector((state) => state.acspaper)

  const [input, setInput] = useState<Array<number>>(initialInput[16])
  const structureArray = Array.from({ length: 16 }, (_, i) => i)

  const ref = useRef(null)

  const [efficiency, setEfficiency] = useState<number>(0)
  const [encodedImg, setEncodedImg] = useState(encoded16)
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isFirst, setIsFirst] = useState<boolean>(true)

  useEffect(() => {
    if (isFirst == false) {
      const data = {
        inputData: input,
        inputWavelength: waveLength,
        inputDeflected_angle: angle,
      }
      axios
        .post(`${REACT_APP_ACSPAPER_API_URL}/acspaper`, data)
        .then((response) => {
          setEfficiency(response.data.output[2])
          setEncodedImg(response.data.graph)
          setLoading(false)
          setDisabled(false)
        })
        .catch((response) => console.log(response))
    }
  }, [input])

  // 실험조건 변경되면 structure도 초기화
  useEffect(() => {
    setInput(initialInput[16])
  }, [waveLength])
  useEffect(() => {
    setInput(initialInput[16])
  }, [angle])

  const changeInput = (index: number, value: number) => {
    if (value === -1) {
      input[index] = 1
      setInput([...input])
    } else if (value === 1) {
      input[index] = -1
      setInput([...input])
    }
    4
  }

  const changeStructure = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target instanceof Image) {
      setIsFirst(false)
      setDisabled(true)
      setLoading(true)
      if (e.target.src === air) {
        changeInput(parseInt(e.target.id), -1)
      } else {
        changeInput(parseInt(e.target.id), 1)
      }
    }
  }

  return (
    <section className={styles.demo}>
      <section id={styles.left}>
        <section id={styles.top}>
          <p className={styles.terminal}>
            It is 16 cell structures.
            <br></br>Find out for yourself how to improve efficiency!
          </p>
        </section>
        <section id={styles.middle}>
          <section className={styles.circle}>
            <div>
              <span id={styles.circle_silicon}></span> &nbsp;{' '}
              <span>Silicon</span>
            </div>
            <br></br>
            <div>
              <span id={styles.circle_air}></span> &nbsp; <span>Air</span>
            </div>
          </section>
          <section className={styles.option}>
            <span id={styles.option_wavelength}>
              Wave Length [nm]
              <Select
                options={waveLengthOption}
                onChange={handleWaveLength}
                defaultValue={{ value: waveLength, label: waveLength }}
              />
            </span>
            <span id={styles.option_angle}>
              Angle [°]
              <Select
                options={angleOption}
                onChange={handleAngle}
                defaultValue={{ value: angle, label: angle }}
              />
            </span>
          </section>
        </section>

        <section id={styles.bottom} ref={ref}>
          {structureArray.map((i) => (
            <button
              type="button"
              onClick={changeStructure}
              key={i}
              disabled={disabled || loading}
            >
              <img
                src={input[i] === -1 ? air : silicon}
                width="48"
                height="90"
                id={`${i}`}
                className={styles.cell16}
              />
            </button>
          ))}
          <br></br>
          <img src={no} width="768" />
          <div className={styles.cell}>
            Select number of cells :{' '}
            <button
              onClick={() => {
                dispatch(setCell(64))
              }}
              id={cell == 64 ? styles.active : styles.deactive}
            >
              64
            </button>
            <button
              onClick={() => {
                dispatch(setCell(32))
              }}
              id={cell == 32 ? styles.active : styles.deactive}
            >
              32
            </button>
            <button
              onClick={() => {
                dispatch(setCell(16))
              }}
              id={cell == 16 ? styles.active : styles.deactive}
            >
              16
            </button>
          </div>
        </section>
      </section>
      <section id={styles.right}>
        <p>
          η : <b>{(efficiency * 100).toFixed(5)}%</b>
        </p>
        <MathComponent tex={String.raw`\mathbf{E}_x/|\mathbf{E}_0 |`} />
        <img
          src={`data:image/png;base64,${encodedImg}`}
          alt="Please wait for the figure"
          height="418"
        />
      </section>
    </section>
  )
}

export default Structure
