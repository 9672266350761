import { useState } from 'react'
import 'styles/Demopage.scss'
import styles from './PoseEstimation.module.scss'
import buttons from 'styles/Button.module.scss'
import poseestimation from 'images/demo/poseestimation.png'

const PoseEstimation = () => {
  const [displayImg, setDisplayImg] = useState<string>('none')
  const [displayP, setDisplayP] = useState<string>('inline')

  const handleDisplay = () => {
    setDisplayImg('inline')
    setDisplayP('none')
  }

  return (
    <section className="demoSection">
      <section className="title">
        Pose Estimation
        <br></br>
        <span id="link">
          The human pose estimation problem is generally formulated as
          estimating joint locations (i.e. the key-points for human pose) on one
          or more people in an image
        </span>
      </section>
      <hr></hr>
      <section className={styles.pose}>
        <div id={styles.imgbox}>
          <p style={{ display: `${displayP}` }}>🗂️ Upload image file</p>
          <span style={{ display: `${displayImg}` }}>
            <img src={poseestimation} />
          </span>
        </div>
        <br></br>
      </section>
      <section>
        <button className={buttons.contact} onClick={handleDisplay}>
          RUN
        </button>
      </section>
    </section>
  )
}

export default PoseEstimation
