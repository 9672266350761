import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import NavBar from './components/common/NavBar'
import Footer from './components/common/Footer'
import Main from './components/pages/Main/Main'
import ACS from './components/pages/ACS2022/ACS'
import Blockchain from './components/pages/Blockchain/Blockchain'
import Localization from './components/pages/Localization/Localization'
import OOD from './components/pages/OOD/OOD'
import PoseEstimation from 'pages/PoseEstimation/PoseEstimation'
import ImageRestoration from 'pages/ImageRestoration/ImageRestoration'
import StressTest from 'pages/StressTest/StressTest'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/1Dgrating" element={<ACS />} />
          <Route path="/PoseEstimation" element={<PoseEstimation />} />
          <Route path="/Blockchain" element={<Blockchain />} />
          <Route path="/Localization" element={<Localization />} />
          <Route path="/OOD" element={<OOD />} />
          <Route path="/ImageRestoration" element={<ImageRestoration />} />
          <Route path="/StressTest" element={<StressTest />} />
        </Routes>
        <Footer />
      </Router>
    </Provider>
  )
}

export default App
