import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import demoList from '../../../locales/demoList'
import styles from './DemoCardSoon.module.scss'
import buttons from 'styles/Button.module.scss'

const DemoCardSoon = () => {
  const inList = Object.values(demoList.soon)

  return (
    <>
      {inList.map(({ title, link, image, description, tag }) => (
        <Link key={title} className={buttons.card} to={'/'}>
          <Card
            style={{
              width: '18rem',
            }}
            className={styles.card}
          >
            <img alt={image} src={`/images/${image}.png`} height="200" />
            <span>Coming Soon</span>
            <button className={buttons.tagSoon}>{`${tag}`}</button>
            <CardBody>
              <CardTitle id={styles.title}>{title}</CardTitle>
              <CardText id={styles.text}>{description}</CardText>
            </CardBody>
          </Card>
        </Link>
      ))}
    </>
  )
}

export default DemoCardSoon
