import { useState } from 'react'
import 'styles/Demopage.scss'
import buttons from 'styles/Button.module.scss'
import styles from './ImageRestoration.module.scss'
import ood from 'images/demo/ood.png'
import axios from 'axios'

const ImageRestoration = () => {
  const { REACT_APP_IMAGERESTORATION_API_URL } = process.env

  const [selectedImages, setSelectedImages] = useState([])
  const [imageData, setImageData] = useState('')

  const handleSubmit = () => {
    if (selectedImages.length === 3) {
      const data = new FormData()
      const keys = Object.keys(selectedImages)
      Object.keys(selectedImages).forEach((key: any, index: number) => {
        const file = selectedImages[key]
        data.append(`image${index + 1}`, file)
      })
      axios
        .post(`${REACT_APP_IMAGERESTORATION_API_URL}/imageRestoration`, data)
        .then((response) => {
          console.log('success')
          const base64Image = response.data.output
          setImageData(`data:image/jpeg;base64,${base64Image}`)
        })
        .catch((response) => console.log('error'))
    }
  }

  const handleFileSelect = (e: any) => {
    const files = e.target.files
    if (files.length === 3) {
      setSelectedImages(files)
    } else {
      alert('Please select 3 images.')
    }
  }

  return (
    <section className="demoSection">
      <section className="title">Image Restoration</section>
      <hr></hr>
      <section>
        <input type="file" name="file" multiple onChange={handleFileSelect} />
        <br></br>
        <br></br>
        <button
          type="submit"
          className={buttons.contact}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <br></br>
        <span>{imageData && <img src={imageData} alt="output" />}</span>
      </section>
      <section></section>
    </section>
  )
}

export default ImageRestoration
