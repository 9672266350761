import styles from './Main.module.scss'
import buttons from 'styles/Button.module.scss'
import DemoCard from './DemoCard'
import DemoCardSoon from './DemoCardSoon'

const Main = () => {
  return (
    <section className={styles.Main}>
      <div className={styles.title}>Try out ML2 apps !</div>
      <div className={styles.card}>
        <DemoCard />
        <DemoCardSoon />
      </div>
    </section>
  )
}

export default Main
