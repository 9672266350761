import { useRef, useState } from 'react'
import 'styles/Demopage.scss'
import styles from './Blockchain.module.scss'
import buttons from 'styles/Button.module.scss'
import ForceGraph3D from 'react-force-graph-3d'
import data from './data.json'
import data2 from './data2.json'

const Blockchain = () => {
  const ref = useRef()
  const [graphData, setgraphData] = useState(data)
  const changeData = () => {
    setgraphData(data2)
  }

  return (
    <section className="demoSection">
      <section className="title">
        Blockchain Graph
        <br></br>
        <span id="link">Subgraph classification & Anomaly detection</span>
      </section>
      <hr></hr>
      <section className={styles.blockchain}>
        <button onClick={changeData} className={buttons.contact}>
          RUN
        </button>
        <ForceGraph3D
          ref={ref}
          graphData={graphData}
          nodeLabel="id"
          nodeAutoColorBy="group"
        />
      </section>
    </section>
  )
}

export default Blockchain
