import { useState } from 'react'
import 'styles/Demopage.scss'
import styles from './StressTest.module.scss'
import buttons from 'styles/Button.module.scss'
import axios from 'axios'

const StressTest = () => {
  const { REACT_APP_STRESSTEST_API_URL } = process.env

  const [selectedFile, setSelectedFile] = useState(null)
  const [output, setOutput] = useState('')

  const handleSubmit = () => {
    const data = new FormData()
    if (selectedFile) {
      data.append('imgfile', selectedFile)
    }

    axios
      .post(`${REACT_APP_STRESSTEST_API_URL}/stressTest`, data)
      .then((response) => {
        setOutput(response.data.class_name)
      })
      .catch((response) => console.log(response))
  }

  const handleFileSelect = (e: any) => {
    setSelectedFile(e.target.files[0])
  }

  return (
    <section className="demoSection">
      <section className="title">Stress Test</section>
      <hr></hr>
      <section className={styles.ood}>
        <input type="file" name="file" onChange={handleFileSelect} />
        <br></br>
        <br></br>
        <button
          type="submit"
          className={buttons.contact}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <br></br>
        <span>
          output : <b>{output}</b>
        </span>
      </section>
      <section></section>
    </section>
  )
}

export default StressTest
