/* eslint-disable multiline-ternary */
import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from 'images/ML2_logo.png'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap'
import styles from './NavBar.module.scss'
import buttons from 'styles/Button.module.scss'
import classNames from 'classnames/bind'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
  const cx = classNames.bind(styles)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <section className={styles.container}>
      <Navbar expand="md" className={styles.NavBar}>
        <NavbarBrand href="/" className={styles.logo}>
          <img src={logo} alt="logo" height="40" width="140.92"></img>
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={styles.toggler}
        >
          <span></span>
          <span></span>
          <span></span>
        </NavbarToggler>
        <Collapse navbar isOpen={isOpen} className={styles.right}>
          <Nav navbar className={styles.nav}>
            <NavItem className={styles.items}>
              <NavLink to="/">
                <button className={buttons.nav}>Apps</button>
              </NavLink>
            </NavItem>
            <NavItem className={styles.items}>
              <button className={buttons.contact}>
                <a
                  href="https://www.kc-ml2.com/ML2_contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </section>
  )
}

export default NavBar
