import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ACSpaperState {
  waveLength: number
  angle: number
  cell: number
}

const initialState: ACSpaperState = {
  waveLength: 900,
  angle: 50,
  cell: 64,
}

export const acspaperSlice = createSlice({
  name: 'acspaper',
  initialState,
  reducers: {
    setWaveLength: (state, { payload }: PayloadAction<number>) => {
      state.waveLength = payload
    },
    setAngle: (state, { payload }: PayloadAction<number>) => {
      state.angle = payload
    },
    setCell: (state, { payload }: PayloadAction<number>) => {
      state.cell = payload
    },
  },
})

export const { setWaveLength, setAngle, setCell } = acspaperSlice.actions

export default acspaperSlice.reducer
