import styles from './ACS.module.scss'
import buttons from 'styles/Button.module.scss'
import 'styles/Demopage.scss'
import Structure from './Structure'
import Structure16 from './Structure16'
import Structure32 from './Structure32'
import acs_paper from 'images/demo/acs/acs_paper.png'
import sample1 from 'images/demo/acs/sample1.png'
import sample2 from 'images/demo/acs/sample2.png'
import sample3 from 'images/demo/acs/sample3.png'
import sample4 from 'images/demo/acs/sample4.png'
import sample5 from 'images/demo/acs/sample5.png'
import sample6 from 'images/demo/acs/sample6.png'
import sample7 from 'images/demo/acs/sample7.png'
import sample8 from 'images/demo/acs/sample8.png'
import sample9 from 'images/demo/acs/sample9.png'
import { useAppSelector } from '../../../redux/hook'

const ACS = () => {
  const { waveLength, angle, cell } = useAppSelector((state) => state.acspaper)

  function CellStructure(): JSX.Element {
    if (cell == 64) {
      return <Structure />
    } else if (cell == 16) {
      return <Structure16 />
    } else if (cell == 32) {
      return <Structure32 />
    }
    return <Structure />
  }

  return (
    <section className="demoSection">
      <section className="title">
        <button className={buttons.tag}>RL</button> &nbsp;Structural
        Optimization of a One-Dimensional Freeform Metagrating Deflector{' '}
        <br></br> via Deep Reinforcement Learning
      </section>
      <h4>Demo</h4>
      <section className={styles.demo}>
        <CellStructure />
        {/* <Structure /> */}
      </section>

      <section className={styles.feedback}>
        <a href="mailto:contact@kc-ml2.com?subject = Feedback&body = Message">
          <img
            src="data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBpZD0iaWNvbi1nby1lbWFpbC0zIiBmaWxsPSIjMDA2NmZmIj48cGF0aCBkPSJNMTUuNzk5IDIuMzk5IDggOS4zMy4yMDEgMi4zOTlBLjk5OC45OTggMCAwIDEgMSAyaDE0Yy4zMjcgMCAuNjE2LjE1Ni43OTkuMzk5Wk0xNiAzLjU1OFYxM2MwIC4wMjEgMCAuMDQyLS4wMDIuMDYzTDExLjM4IDcuNjc1di0uMDFMMTYgMy41NThabS0uNTkgMTAuMzU1QS45OTYuOTk2IDAgMCAxIDE1IDE0SDFhLjk5Ni45OTYgMCAwIDEtLjQxLS4wODdsNC43ODMtNS41OEw4IDEwLjY3bDIuNjI3LTIuMzM1IDQuNzgyIDUuNTc5Wm0tMTUuNDA4LS44NUExLjAxNSAxLjAxNSAwIDAgMSAwIDEzVjMuNTU4bDQuNjI1IDQuMTEtLjAwNS4wMDctNC42MTggNS4zODhaIiBmaWxsPSIjMDA2NmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD48L3N2Zz4="
            height="24"
          ></img>{' '}
          Give us feedback!
        </a>
      </section>

      <h4>ACS Photonics publication</h4>
      <br></br>
      <section className="description">
        <div id="photo">
          <img src={acs_paper} height="238" width="515" />
        </div>
        <div id="text">
          The increasing demand on a versatile high-performance metasurface
          requires a freeform design method that can handle a huge design space,
          which is many orders of magnitude larger than that of conventional
          fixed-shape optical structures. In this work, we formulate the
          designing process of one-dimensional freeform Si metasurface beam
          deflectors as a reinforcement learning problem to find their optimal
          structures consistently without requiring any prior metasurface data.
          <br></br>
          <a
            id="link"
            href="https://pubs.acs.org/doi/abs/10.1021/acsphotonics.1c00839"
            target="_blank"
          >
            <button className={buttons.link}>ACS paper</button>
          </a>
        </div>
      </section>
      <hr></hr>
      <section className={styles.sample}>
        <h4>Optimized Structures</h4>
        <br></br>
        {waveLength == 900 && angle === 50 ? (
          <img src={sample1} height="461" width="1052" />
        ) : null}
        {waveLength == 900 && angle === 60 ? (
          <img src={sample2} height="461" width="1052" />
        ) : null}
        {waveLength == 900 && angle === 70 ? (
          <img src={sample3} height="461" width="1052" />
        ) : null}
        {waveLength == 1000 && angle === 50 ? (
          <img src={sample4} height="461" width="1052" />
        ) : null}
        {waveLength == 1000 && angle === 60 ? (
          <img src={sample5} height="461" width="1052" />
        ) : null}
        {waveLength == 1000 && angle === 70 ? (
          <img src={sample6} height="461" width="1052" />
        ) : null}
        {waveLength == 1100 && angle === 50 ? (
          <img src={sample7} height="461" width="1052" />
        ) : null}
        {waveLength == 1100 && angle === 60 ? (
          <img src={sample8} height="461" width="1052" />
        ) : null}
        {waveLength == 1100 && angle === 70 ? (
          <img src={sample9} height="461" width="1052" />
        ) : null}

        <div className={styles.optimal}>
          <span id={styles.first}>[Optimized structure]</span>
          <span id={styles.second}>
            This is the optimized structure proposed in the paper. <b>Si</b> is
            represented by blue cell, and <b>air</b> by white cell.
          </span>
        </div>
        <div className={styles.setting}>
          <b>Wave Length</b> : {waveLength}nm, <b>Angle</b> : {angle}°
        </div>
      </section>
    </section>
  )
}
export default ACS
