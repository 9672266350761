import { useState } from 'react'
import 'styles/Demopage.scss'
import styles from './OOD.module.scss'
import buttons from 'styles/Button.module.scss'
import ood from 'images/demo/ood.png'
import axios from 'axios'

const OOD = () => {
  const { REACT_APP_OOD_API_URL } = process.env

  const [selectedFile, setSelectedFile] = useState(null)
  const [imageData, setImageData] = useState('')

  const handleSubmit = () => {
    const data = new FormData()
    if (selectedFile) {
      data.append('imgfile', selectedFile)
    }

    axios
      .post(`${REACT_APP_OOD_API_URL}/orientedObjectDetection`, data)
      .then((response) => {
        const base64Image = response.data.output
        setImageData(`data:image/jpeg;base64,${base64Image}`)
      })
      .catch((response) => console.log(response))
  }

  const handleFileSelect = (e: any) => {
    setSelectedFile(e.target.files[0])
  }

  return (
    <section className="demoSection">
      <section className="title">OOD</section>
      <hr></hr>
      <section className={styles.ood}>
        <input type="file" name="file" onChange={handleFileSelect} />
        <br></br>
        <br></br>
        <button
          type="submit"
          className={buttons.contact}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <br></br>
        <span>{imageData && <img src={imageData} alt="output" />}</span>
      </section>
      <section></section>
    </section>
  )
}

export default OOD
