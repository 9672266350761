import 'styles/Demopage.scss'
import styles from './Localization.module.scss'
import buttons from 'styles/Button.module.scss'

const Navigation = () => {
  return (
    <section className="demoSection">
      <section className="title">
        <button className={buttons.tag}>CV</button> &nbsp; Image Retrieval based
        Robot Global Localization
      </section>
      <h4>Demo Video</h4>
      <div className={styles.demo}>
        <div id={styles.label}>Summary</div>
        <span>
          Global localization is localization with a given map, but does not use
          recent location records. These are simulations that estimate the
          current position based on a single camera input.
        </span>
      </div>
      <div className={styles.colorPoint}>
        <div id={styles.yellow}></div> <p>Current Position</p>{' '}
        &nbsp;&nbsp;&nbsp;
        <div id={styles.red}></div> <p>Estimated Position</p>&nbsp;&nbsp;&nbsp;
        <div id={styles.blue}></div> <p>Explored Path</p>
      </div>
      <div className={styles.demo_gif}>
        <div id={styles.label}>Small Space</div>
        <img
          src="https://ml2-ai-product.s3.ap-northeast-2.amazonaws.com/navigation/small_crop.gif"
          alt="small"
        />
      </div>
      <div className={styles.demo_gif}>
        <div id={styles.label}>Large Space</div>
        <img
          src="https://ml2-ai-product.s3.ap-northeast-2.amazonaws.com/navigation/large_crop.gif"
          alt="large"
        />
      </div>
      <hr></hr>
      <section className="description" id={styles.bottom}>
        <div id="photo">
          <img
            src="https://ml2-ai-product.s3.ap-northeast-2.amazonaws.com/navigation/overview2.png"
            alt="overview"
            height="238"
            width="515"
            style={{ border: '1px solid black' }}
          />
        </div>
        <div id="text">
          Monte Carlo localization (MCL) is a widely used method for estimating
          the location of mobile robots. MCL works well in most environments.
          However, it does not work well with "kidnapped" problems, which have
          no previous consecutive sensor input. <b>"Image retrieval"</b> is
          effective in these "kidnapped" problems. "Retrieval" does not require
          consecutive sensor input, and "image" contains more information than
          other types of sensors. The problem with "Image" is that there is so
          much information that it is difficult to extract the information
          needed for our purpose. This problem can be solved using deep
          learning. Using Deep learning, we can extract the descriptor of the
          current camera image and compare it with the recorded descriptors in
          the database to find the most similar explored point. Through this,
          the current position can be estimated only by a single camera input.
          <br></br>
          <a
            id="link"
            href="https://www.kc-ml2.com/posts/blog_RobotGlobalLocalization"
            target="_blank"
          >
            <button className={buttons.link}>Details</button>
          </a>
        </div>
      </section>
    </section>
  )
}

export default Navigation
