import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import demoList from '../../../locales/demoList'
import styles from './DemoCard.module.scss'
import buttons from 'styles/Button.module.scss'

const DemoCard = () => {
  const inList = Object.values(demoList.in)
  const outList = Object.values(demoList.out)

  return (
    <>
      {inList.map(({ title, link, image, description, tag }) => (
        <Link key={title} className={buttons.card} to={`${link}`}>
          <Card
            style={{
              width: '18rem',
            }}
            className={styles.card}
          >
            <img alt={image} src={`/images/${image}.png`} height="200" />
            <button className={buttons.tag}>{`${tag}`}</button>
            <CardBody>
              <CardTitle id={styles.title}>{title}</CardTitle>
              <CardText id={styles.text}>{description}</CardText>
            </CardBody>
          </Card>
        </Link>
      ))}
      {outList.map(({ title, link, image, description, tag }) => (
        <a key={title} className={buttons.card} href={link} target="_blank">
          <Card
            style={{
              width: '18rem',
            }}
            className={styles.card}
          >
            <img alt={image} src={`/images/${image}.png`} height="200" />
            <button className={buttons.tag}>{`${tag}`}</button>
            <CardBody>
              <CardTitle id={styles.title}>{title}</CardTitle>
              <CardText id={styles.text}>{description}</CardText>
            </CardBody>
          </Card>
        </a>
      ))}
    </>
  )
}

export default DemoCard
